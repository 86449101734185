import CheckPermission from './checkPermission.service';
import jejakEnum from './../constants/jejak-enum'
class jejaks {
    viewJejaks(){
        const checkPermission = new CheckPermission();
        let listJejaks = [];
        if(checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.View.PasienBelumMasuk')){
            listJejaks.push(jejakEnum.PASIEN_BELUM_MASUK);
        }
        if(checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.View.PasienDatang')){
            listJejaks.push(jejakEnum.PASIEN_DATANG);
        }
        if(checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.View.TidakJadiDatang')){
            listJejaks.push(jejakEnum.TIDAK_JADI_DATANG);
        }
        if(checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.View.DirujukKetempatLain')){
            listJejaks.push(jejakEnum.DIRUJUK_KETEMPAT_LAIN);
        }
        if(checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.View.DalamPerawatan')){
            listJejaks.push(jejakEnum.DALAM_PERAWATAN);
        }
        if(checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.View.PasienPulang')){
            listJejaks.push(jejakEnum.PASIEN_PULANG);
        }
        if(checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.View.DalamProsesPembayaran')){
            listJejaks.push(jejakEnum.DALAM_PROSES_PEMBAYARAN);
        }
        if(checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.View.SudahTerbayarkan')){
            listJejaks.push(jejakEnum.SUDAH_TERBAYARKAN);
        }
        if(checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.View.Closed')){
            listJejaks.push(jejakEnum.CLOSED);
        }
        return listJejaks;
    }
    isAllowDisabled(jejak){
        let disabled = true;
        const checkPermission = new CheckPermission();
        if(checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.Edit.ALL')){
            disabled = false;
        } else {
            if(jejak == jejakEnum.PASIEN_BELUM_MASUK && checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.Edit.PasienBelumMasuk')){
                disabled = false;
            }
            if(jejak == jejakEnum.PASIEN_DATANG && checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.Edit.PasienDatang')){
                disabled = false;
            }
            if(jejak == jejakEnum.DALAM_PERAWATAN && checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.Edit.DalamPerawatan')){
                disabled = false;
            }
        }
        return disabled;
    }
    isAllowDisabled2(jejak){
        let disabled = true;
        const checkPermission = new CheckPermission();
        if(checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.Edit.ALL')){
            disabled = false;
        } else {
            if(jejak == jejakEnum.PASIEN_PULANG && checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.Edit.PasienPulang')){
                disabled = false;
            }
            if(jejak == jejakEnum.DALAM_PROSES_PEMBAYARAN && checkPermission.isCanAccess('BackOffice.TransaksiReferral.Jejak.Edit.DalamProsesPembayaran')){
                disabled = false;
            }
        }
        return disabled;

    }
    listUpdateJejak(lastJejakStage){
        const checkPermission = new CheckPermission();
        let listJejak = [];
        if (checkPermission.isCanAccess('JejakService.ADD.Jejak.SkipValidation')) {
            listJejak = [
                jejakEnum.PASIEN_BELUM_MASUK,
                jejakEnum.PASIEN_DATANG,
                jejakEnum.TIDAK_JADI_DATANG,
                jejakEnum.DIRUJUK_KETEMPAT_LAIN,
                jejakEnum.DALAM_PERAWATAN,
                jejakEnum.PASIEN_PULANG,
                jejakEnum.DALAM_PROSES_PEMBAYARAN,
                jejakEnum.SUDAH_TERBAYARKAN,
                jejakEnum.CLOSED
            ];
        } else {
            if (checkPermission.isCanAccess('JejakService.ADD.Jejak.PasienDatang') && lastJejakStage === jejakEnum.PASIEN_BELUM_MASUK) {
                listJejak.push(jejakEnum.PASIEN_DATANG);
            }
            if (checkPermission.isCanAccess('JejakService.ADD.Jejak.TidakJadiDatang') && lastJejakStage === jejakEnum.PASIEN_BELUM_MASUK) {
                listJejak.push(jejakEnum.TIDAK_JADI_DATANG);
            }
            if (checkPermission.isCanAccess('JejakService.ADD.Jejak.DirujukKetempatLain') && lastJejakStage === jejakEnum.PASIEN_BELUM_MASUK) {
                listJejak.push(jejakEnum.DIRUJUK_KETEMPAT_LAIN);
            }
            if (checkPermission.isCanAccess('JejakService.ADD.Jejak.DalamPerawatan') && lastJejakStage === jejakEnum.PASIEN_DATANG) {
                listJejak.push(jejakEnum.DALAM_PERAWATAN);
            }
            if (checkPermission.isCanAccess('JejakService.ADD.Jejak.PasienPulang') && lastJejakStage === jejakEnum.DALAM_PERAWATAN) {
                listJejak.push(jejakEnum.PASIEN_PULANG);
            }
            if (checkPermission.isCanAccess('JejakService.ADD.Jejak.PasienPulang') && lastJejakStage === jejakEnum.PASIEN_DATANG) {
                listJejak.push(jejakEnum.PASIEN_PULANG);
            }
            if (checkPermission.isCanAccess('JejakService.ADD.Jejak.DalamProsesPembayaran') && lastJejakStage === jejakEnum.PASIEN_PULANG) {
                listJejak.push(jejakEnum.DALAM_PROSES_PEMBAYARAN);
            }
            if (checkPermission.isCanAccess('JejakService.ADD.Jejak.SudahTerbayarkan') && lastJejakStage === jejakEnum.DALAM_PROSES_PEMBAYARAN) {
                listJejak.push(jejakEnum.SUDAH_TERBAYARKAN);
            }
        }
        return listJejak;
    }
    listNotification(){
        const checkPermission = new CheckPermission();
        let listJejaks = [];
        if(checkPermission.isCanAccess('BackOffice.Notification.TransaksiReferral.PasienBelumMasuk')){
            listJejaks.push(jejakEnum.PASIEN_BELUM_MASUK);
        }
        if(checkPermission.isCanAccess('BackOffice.Notification.TransaksiReferral.PasienDatang')){
            listJejaks.push(jejakEnum.PASIEN_DATANG);
        }
        if(checkPermission.isCanAccess('BackOffice.Notification.TransaksiReferral.TidakJadiDatang')){
            listJejaks.push(jejakEnum.TIDAK_JADI_DATANG);
        }
        if(checkPermission.isCanAccess('BackOffice.Notification.TransaksiReferral.DirujukKetempatLain')){
            listJejaks.push(jejakEnum.DIRUJUK_KETEMPAT_LAIN);
        }
        if(checkPermission.isCanAccess('BackOffice.Notification.TransaksiReferral.DalamPerawatan')){
            listJejaks.push(jejakEnum.DALAM_PERAWATAN);
        }
        if(checkPermission.isCanAccess('BackOffice.Notification.TransaksiReferral.PasienPulang')){
            listJejaks.push(jejakEnum.PASIEN_PULANG);
        }
        if(checkPermission.isCanAccess('BackOffice.Notification.TransaksiReferral.DalamProsesPembayaran')){
            listJejaks.push(jejakEnum.DALAM_PROSES_PEMBAYARAN);
        }
        if(checkPermission.isCanAccess('BackOffice.Notification.TransaksiReferral.SudahTerbayarkan')){
            listJejaks.push(jejakEnum.SUDAH_TERBAYARKAN);
        }
        if(checkPermission.isCanAccess('BackOffice.Notification.TransaksiReferral.Closed')){
            listJejaks.push(jejakEnum.CLOSED);
        }
        return listJejaks;
    }
}
export default jejaks = new jejaks();

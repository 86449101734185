<template>
  <div>
    <base-header :base-title="headTitle"></base-header>
    <div
      class="kt-content kt-grid__item kt-grid__item--fluid"
      id="kt_content"
    >
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="headTitle"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
              <router-link
                class="btn btn-default btn-bold btn-upper btn-font-sm mr-2"
                :to="{ name: 'transaksi-referral' }"
              >
                <i class="flaticon2-back"></i>
                {{ $t('button.previous') }}
              </router-link>
              <template v-if="checkPermission('TransaksiReferralResource.PUT.TransaksiReferral')">
                <router-link
                  class="btn btn-default btn-bold btn-upper btn-font-sm mr-2"
                  :to="{ name: 'transaksi-referral-edit',  params: { id: transaksiReferral.id }}"
                >
                  <i class="la la-pencil-square"></i>
                  {{ $t('button.edit') }}
                </router-link>
              </template>
              <template v-if="checkPermission('Backoffice.TransaksiReferral.PrintBilling')">
                <button
                  class="btn btn-default btn-bold btn-upper btn-font-sm"
                  @click="print()"
                >
                  <i class="fa fa-print"></i>
                  {{ $t('button.print') }}
                </button>
              </template>
            </div>
          </div>
        </div>
        <div
          class="kt-portlet__body"
          v-if="isLoaded"
        >
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.name') }}:</label>
                <label class="col-7 col-form-label">{{transaksiReferral.nama}}</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.tanggalLahir') }}:</label>
                <label class="col-7 col-form-label">{{transaksiReferral.tanggalLahir | formatDate}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.medicalNo') }}:</label>
                <label class="col-7 col-form-label">{{transaksiReferral.medicalRecordNo}} </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.tanggalDaftar') }}:</label>
                <label class="col-7 col-form-label">{{ tanggalDaftar }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.nomorRegistrasi') }}:</label>
                <label class="col-7 col-form-label">{{transaksiReferral.nomorRegistrasi}} </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.phoneNo') }}:</label>
                <label class="col-7 col-form-label">{{telepon}}</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.alamat') }}:</label>
                <label class="col-7 col-form-label">{{transaksiReferral.alamat}} </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.perujuk') }}:</label>
                <label class="col-7 col-form-label">
                    <a href="#" class="font-weight-bold" @click="openModalPerujuk = true">{{ fullName }}</a>
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.diagnosa') }}:</label>
                <label class="col-7 col-form-label">{{transaksiReferral.diagnosa}} </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.catatan') }}:</label>
                <label class="col-7 col-form-label">{{ transaksiReferral.catatan }}</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.dataPenunjang') }}:</label>
                <div class="col-7">
                  <div
                    class="row"
                    v-if="transaksiReferral.dataPenunjangs"
                  >
                    <div
                      @click="openImageModal(value.dataUrl, value.title)"
                      class="col-6 mt-2 text-center"
                      v-for="value in transaksiReferral.dataPenunjangs"
                      :key="value.id"
                    >
                      <img
                        :src="imgUrl + value.dataUrl"
                        alt="image"
                        class="img-thumbnail"
                      >
                      <small>{{ value.title }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.poly') }}:</label>
                <label class="col-7 col-form-label">{{transaksiReferral.tujuanRujukan.nama}} </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.guarantor') }}:</label>
                <label class="col-7 col-form-label">{{ transaksiReferral.jenisPenjamin.nama }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.tindakan') }}:</label>
                <label class="col-7 col-form-label">{{ tindakan }} </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.kelas') }}:</label>
                <label class="col-7 col-form-label">{{ kelas  }}</label>
              </div>
            </div>
          </div>
          <div
            class="row"
            v-if="checkPermission('TransaksiReferralService.VIEW.Fee')"
          >
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.totalBiaya') }}:</label>
                <label class="col-7 col-form-label">{{ transaksiReferral.totalBiaya | seperator }} </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.fee') }}:</label>
                <label class="col-7 col-form-label">{{ transaksiReferral.fee  | seperator }}</label>
              </div>
            </div>
          </div>
          <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row mb-2">
                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.jejaks') }}:</label>
                <label class="col-7">
                  <list-jejak :jejaks="transaksiReferral.jejaks"></list-jejak>
                </label>
              </div>
            </div>
            <div class="col-auto">
              <template v-for="(jejak,index) in listJejak">
                <button
                  class="btn btn-label-primary btn-block text-left"
                  type="button"
                  @click="addJejak(jejak)"
                  :key="index"
                >
                  <i class="flaticon2-plus"></i>
                  Tambah Jejak {{ $t("EnumStage."+ jejak) }}
                </button>
              </template>
            </div>
          </div>
          <div
            id="printMe"
            v-show="false"
          >
            <print-billing :transaksiReferral="transaksiReferral"></print-billing>
          </div>
        </div>
        <div
          v-else
          class="m-auto p-5 d-flex flex-row h-100"
        >
          <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
          <h5 class="my-auto pl-5">Loading ...</h5>
        </div>
        <form-image
          v-if="openModalImage"
          :show-modal.sync="openModalImage"
          :modal-name="'modalImage'"
          :modal-data="imageLink"
        ></form-image>
        <form-view
          v-if="openModalPerujuk"
          :show-modal.sync="openModalPerujuk"
          :modal-name="'modalPerujuk'"
          :modal-data="transaksiReferral.perujuk"
        ></form-view>
        <form-registrasi
          v-if="openModalRegistrasi"
          :modal-name="'modalNomorRegistrasi'"
          @update="update"
		  @showModalRegistrasi="showModalRegistrasi"
          :modal-data="transaksiReferral"
        >
        </form-registrasi>
      </div>
    </div>
  </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const TransaksiReferralRepository = RepositoryFactory.get("transaksiReferral");
import SweetAlertTemplate from "./../_general/SweetAlert";
const SweetAlert = Vue.extend(SweetAlertTemplate);
import BlockUI from "./../_js/BlockUI";
const blockUI = new BlockUI();
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import TransaksiReferral from "./../../model/transaksi-referral-model";
import ListJejak from "./_components/ListJejak";
import jejaks from "./../../services/jejak.service";
import Jejak from "./../../model/jejak-model.js"
import PrintBilling from "./TransaksiReferralPrint";
import LoadSpinner from "./../_general/LoadSpinner";
export default {
  components: {
    BaseHeader,
    BaseTitle,
    ListJejak,
    PrintBilling,
    LoadSpinner,
    FormImage: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(/* webpackChunkName: "modal-view-image" */ "./_components/FormImage.vue")
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
    FormView: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(/* webpackChunkName: "modal-view-perujuk" */ "./../perujuks/_components/FormVIew")
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
    FormRegistrasi: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(/* webpackChunkName: "modal-nomor-registrasi" */ "./_components/FormNomorRegistrasi")
          );
        }, 1000);
      }),
      loading: LoadSpinner
    })
  },
  data() {
    var vx = this;
    return {
      transaksiReferral: new TransaksiReferral(),
      isLoaded: false,
      headTitle: "Transaksi Referral",
      imgUrl: process.env.VUE_APP_API_URL + '/gallery/api/image/alt/',
      listJejak: [],
      imageLink: null,
      openModalImage: false,
      openModalPerujuk: false,
      openModalRegistrasi: false,
    }
  },
  filters: {
    seperator: function (value) {
      if (value !== null) {
        const parts = value.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return parts.join(',');
      }
      return value;
    },
    formatDate: function (value) {
      return moment(value).format('DD MMMM YYYY');
    }
  },
  methods: {
    openImageModal: function (link, title) {
      this.imageLink = {
         link : this.imgUrl + link,
         title: title
      }
      this.openModalImage = true;
    },
    print: function () {
      this.$htmlToPaper('printMe');
    },
    async onInit(id, resolve) {
      this.isLoaded = false;
      await TransaksiReferralRepository.find(id)
        .then(res => {
          this.isLoaded = true;
          this.transaksiReferral = res.data;
          this.listJejak = jejaks.listUpdateJejak(this.transaksiReferral.jejakTerakhirStage);
          if (resolve != undefined) {
            resolve(true);
          }
        })
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    },
    addJejak: function (value) {
      var vx = this;
      if(value !== 'PASIEN_DATANG'){
        let instance = new SweetAlert().showConfirm({
          swalTitle: "Apakah Anda yakin?",
          swalText: "Tekan ok untuk mengubah jejak menjadi " + vx.$i18n.t("EnumStage." + value),
          swalType: "info",
          onConfirmButton: function () {
            let jejak = new Jejak();
            jejak.enumStage = value;
            jejak.transaksiId = vx.transaksiReferral.id;
            jejak.timestamp = moment().local().format('YYYY-MM-DDTHH:mm:ss');
            blockUI.blockPage();
            vx.update(jejak);
          },
          onCancelButton: function () {
            blockUI.unblockPage();
          }
        })
      } else {
        vx.showModalRegistrasi(true)
      }
    },
    showModalRegistrasi(val){
		this.openModalRegistrasi = val
    },
    async update(jejak) {
      var vx = this;
      await TransaksiReferralRepository.addJejak(jejak)
        .then(res => {
          new Promise((resolve, reject) => {
            vx.onInit(vx.transaksiReferral.id, resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan Jejak berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockPage();
              }
            });
          });
        })
        .catch(err => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan Jejak gagal disimpan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockPage();
            }
          });
        })
    }
  },
  computed: {
    fullName: function () {
      const fname = (this.transaksiReferral.perujuk.firstName) ? this.transaksiReferral.perujuk.firstName : '';
      const lname = (this.transaksiReferral.perujuk.lastName) ? this.transaksiReferral.perujuk.lastName : '';
      return fname + ' ' + lname;
    },
    tindakan: function () {
      if (this.transaksiReferral.tindakan) {
        return this.transaksiReferral.tindakan.parent.nama + ' - ' + this.transaksiReferral.tindakan.nama;
      } else {
        return '';
      }
    },
    kelas: function () {
      return (this.transaksiReferral.kelas) ? this.transaksiReferral.kelas.nama : '';
    },
    tanggalDaftar: function () {
      return moment(this.transaksiReferral.createdDate).local().format('DD MMMM YYYY');
    },
    telepon: function () {
      let x = '';
      if (this.transaksiReferral.telepon != null && this.transaksiReferral.telepon != '') {
        x = this.transaksiReferral.telepon.replace(/\D/g, "");
        if (x[0] == '6') {
          x = '0' + x.substr(2, x.length - 1);
        }
      }
      return x;
    }
  },
  created() {
    this.onInit(this.$route.params.id)
  },
}
</script>

<style>
</style>

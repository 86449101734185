export default function TransaksiReferral() {
    return {
        id: null,
        nama: null,
        tanggalLahir: null,
        telepon: null,
        alamat: null,
        diagnosa: null,
        catatan: null,
        dataContentType: null,
        data: null,
        tujuanRujukan: null,
        jenisPenjamin: null,
        dataPenunjangs: [],
        jejaks: [],
        perujuk: null,
        totalBiaya: null,
        fee: null,
        status: null,
        jejakTerakhirStage: null,
        jejakTerakhirTimestamp: null,
        nomorSurat: null,
        cetakan: null,
        createdBy: null,
        createdDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        nextJejak: null,
        skemaRujukanFee: null,
        tindakan: null,
        kelas: null,
        medicalRecordNo: null,
    }
}

<template>
    <div class="kt-list kt-list--badge">
        <div class="kt-list__item" v-for="(jejak, index) in listJejak" :key="jejak.id">
            <span class="kt-list__badge" :class="{'kt-list__badge--success': index == 0}"></span>
            <span class="kt-list__text" :class="{'kt-font-bolder': index == 0}">{{ $t("EnumStage."+ jejak.enumStage) }}</span>
            <span class="kt-list__time" :class="{'kt-font-bolder': index == 0}">
                {{ jejak.timestamp | tanggal }} <br/>
                {{ jejak.timestamp | time }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        jejaks: { type: Array },
    },
    filters:{
        tanggal: function(val){
            return moment(val).format('DD MMM YYYY');
        },
        time: function(val){
            return moment(val).format('HH:mm:ss');
        }
    },
    data(){
        return {
            listJejak: []
        }
    },
    mounted(){
        this.listJejak = this.jejaks.reverse()
    }
}
</script>

<style>

</style>
